import { getRoleOptions } from "constants/roleOptions";

export function hasAnyRole(userRole) {
   return hasAnyOfRoles(userRole, getRoleOptions())
}

export function hasRoleAdmin(userRole) {
   return hasAnyOfRoles(userRole, [getRoleOptions()[0]], "");
}

export function hasRoleAdminMenu(userRole) {
   return hasAnyOfRoles(userRole, [getRoleOptions()[0], getRoleOptions()[1]], "");
}

export function hasRoleBuseer(userRole) {
   return hasAnyOfRoles(userRole, [getRoleOptions()[7]], "");
}

export function hasRoleDirector(userRole) {
   return userRole?.some(role => role.includes(getRoleOptions()[4]));
}

export function hasRoleManager(userRole) {
   return userRole.some(role => role.includes(getRoleOptions()[10]));
}

export function hasOnlyRoleManager(userRole) {
   return userRole.some(role => role.includes(getRoleOptions()[10])) && !this.hasRoleAdminOrDirector(userRole);
}

export function hasRoleAdminOrDirector(userRole, extra = "") {
   return hasAnyOfRoles(userRole, [getRoleOptions()[0], getRoleOptions()[4]], extra);
}

export function hasRoleAdminOrgOrDirector(userRole, extra = "") {
   return hasAnyOfRoles(userRole, [getRoleOptions()[0], getRoleOptions()[1], getRoleOptions()[4]], extra);
}

export function hasRoleMenu(userRole, extra = "") {
   return hasAnyOfRoles(userRole, [getRoleOptions()[0], getRoleOptions()[1], getRoleOptions()[4], getRoleOptions()[10]], extra);
}

export function hasRoleKitchen(userRole, extra = "") {
   return hasAnyOfRoles(userRole, [getRoleOptions()[8]], extra);
}

export function hasRoleForMySections(userRole, extra = "") {
   return hasAnyOfRoles(userRole, [getRoleOptions()[4], getRoleOptions()[7], getRoleOptions()[8], getRoleOptions()[10], getRoleOptions()[11], getRoleOptions()[12]], extra);
}

export function hasRoleForPDA(userRole, extra = "") {
   return hasAnyOfRoles(userRole, [getRoleOptions()[4], getRoleOptions()[7], getRoleOptions()[10], getRoleOptions()[11], getRoleOptions()[12]], extra);
}

export function hasRoleForRequest(userRole, extra = "") {
   return hasAnyOfRoles(userRole, [getRoleOptions()[4], getRoleOptions()[7], getRoleOptions()[10], getRoleOptions()[11], getRoleOptions()[12]], extra);
}

export function hasRoleAdminOrDirectorOrManager(userRole, extra = "") {
   return hasAnyOfRoles(userRole, [getRoleOptions()[0], getRoleOptions()[4], getRoleOptions()[10]], extra);
}

export function hasRoleRating_Viewer(userRole) {
   return hasAnyOfRoles(userRole, [getRoleOptions()[16]], "");
}

export function hasAnyOfRoles(userRole, roleOptions, extra = "") {
   return roleOptions.some(role => userRole?.includes(extra + role));
}

export default class functions {
   static hasAnyRole(userRole) { return hasAnyRole(userRole); };
   static hasRoleAdminOrDirectorOrManager(userRole, roleOptions, extra) { return hasRoleAdminOrDirectorOrManager(userRole, roleOptions, extra); };
   static hasAnyOfRoles(userRole, roleOptions, extra) { return hasAnyOfRoles(userRole, roleOptions, extra); };
   static hasRoleAdmin(userRole) { return hasRoleAdmin(userRole); };
   static hasRoleDirector(userRole) { return hasRoleDirector(userRole); };
   static hasRoleAdminOrDirector(userRole, extra) { return hasRoleAdminOrDirector(userRole, extra); };
   static hasRoleKitchen(userRole, extra) { return hasRoleKitchen(userRole, extra); };
}