import { Checkbox, Fab, FormControlLabel, Grid, Tooltip } from "@mui/material";
import { PayIcon } from "assets/svg/SvgIcons";
import CustomTypography from "components/Typography/CustomTypography";
import { useTranslation } from "react-i18next";
import { payCloseAllFabButtonStyle, payCloseAllIconDialogButtonStyle, payCloseAllLabelStyle } from "themes/defaultThemes";


function CustomPayOrderPayCloseAllFirstPage({ checked, onCheckedChange, handlePayCloseAll }) {
    const { t } = useTranslation();

    const handleCheckboxChange = (event) => {
        onCheckedChange(event.target.checked); // Pass the checked state to the parent component
    };
    
    //============================ RENDER
    return (
        <Grid container spacing={2} sx={{ margin: "10px" }}>
            <Grid item xs={12} sx={{ display: "flex" }}>
                <Grid container gap={1}>
                    <Grid item xs={12}>
                        <CustomTypography variant="body2" text={t('pda.payCloseAll.label')} sx={payCloseAllLabelStyle()} />
                    </Grid>

                    {/* Advanced Button with Checkbox */}
                    <Grid item xs={12} sx={{ mt: "30px" }}>
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    onChange={(event) => handleCheckboxChange(event)}
                                    color="primary"
                                />
                            }
                            label={t('pda.payCloseAll.advanceCheckboxLabel')}
                        /> */}
                    </Grid>
                </Grid>

                <Tooltip arrow title={t('pda.payCloseAll.btnTooltipDefault')} sx={{ zIndex: 1000000 }}>
                    <Fab
                        color="primary"
                        sx={payCloseAllFabButtonStyle()}
                        onClick={handlePayCloseAll}
                    >
                        <PayIcon style={payCloseAllIconDialogButtonStyle()} />
                    </Fab>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default CustomPayOrderPayCloseAllFirstPage;